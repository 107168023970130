<template>
  <div class="casa__background">
    <router-view></router-view>
    <ScrollTop />
  </div>
</template>

<script>
import ScrollTop from "@/components/ScrollTop";

export default {
  components: { ScrollTop },
};
</script>

<style scoped>
.casa__background {
  background-image: url("../../assets/conteudo_casas_background.png");
  background-size: cover;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f9f7ed;
}
</style>
